.date-label {
    width: 299px;
    height: 42px;
    border: 1px solid #E9E9E9;
}

.dropdown-body {
    width: 339px;

}

.checkbox-input.form-check {
    display: flex;
}

.checkbox-input {
    .form-check-input {
        padding: 0 !important;
        height: 15px;
        width: 15px;
        border: 1px solid #D2D3D2;
        border-radius: 4px;
        margin-top: 4px;
    }

}