
.download-button{
    width: max-content;
}

.expanded-offers{
  .custom-data-table {
    .rdt_TableBody {
      height: fit-content;
      background-color: #F3F7F6 !important;
    }

    .sc-ivKqzx,
    .rdt_Table,
    .rdt_Table .rdt_TableHeadRow,
    .rdt_TableRow,
    .rdt_TableCell,
    .rdt_TableCol {
      background-color: #F3F7F6 !important;
    }
  }

  .rdt_Pagination {
    display: none;
    background-color: #F3F7F6 !important;
  }
}