$background-color_1: #e8f5f2;
$background-color_2: transparent;
$background-color_3: white;

* {
	box-sizing: border-box;
	font-family: 'Poppins';
}

html,
body {
	font-family: 'Poppins';
}

body {
	margin: 0;
	padding: 0;
	// background-color:#FDFDFD
	background-color: $background-color_1;
}

.notification-btn {
	background-color: $background-color_2;
	border: none;

	&:hover {
		background-color: $background-color_2 !important;
	}
};

.dropdown-toggle {
	&::after {
		content: unset;
	}
};

.profile-dropdown {
	.dropdown-toggle {
		&::after {
			content: unset;
		}
	}

	.dropdown-menu[data-bs-popper] {
		top: 100%;
		left: -13rem;
		margin-top: 0.5rem;
		min-width: 15rem;
		background-color: $background-color_3;
		padding:24px;
	}
}

.content-center {
	display: flex;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 30px;
	min-height: calc(100vh - 123px);
	justify-content: center;
}

.profile {
	min-height: 134px;
	min-width: 342px;
}

.search-input-box {
	border: 1.5px solid #E9E9E9;
	border-radius: 8px;
	height: 42px;
}
.search-input-box:hover {
	border-color: #388173 !important;
}
.search-input-box:focus-within {
	border-color: #388173 !important;
}
.search-text-input{
	box-shadow:none;
}
.search-text-input:focus {
    outline: 0 !important;
    border-color: initial;
    box-shadow: none;
}
.cursor-pointer {
    cursor: pointer;
}
.single-line-ellipsis{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.role-card:active {
	border: 1px solid #1C2120;
}
.role-card:hover {
	border: 1px solid #1C2120;
}
// @media only screen and (min-width:1400px) {
// 	.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
// 		max-width: 1360px;
// 	}
// }
input:focus {
	outline: 1.5px solid #E9E9E9;
}

::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	background-color: #FFFFFF;
}

::-webkit-scrollbar-thumb {
	border-radius: 40px;
	background-color: #E9E9E9;
}