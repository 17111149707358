//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Custom variables
@import "components/variables.custom";
@import "./core/components/variables";

// Custom functions & mixins
// @import "./core/base/functions";
// @import "./core/base/mixins";
// @import "./core/components/mixins";

// Bootstrap initializaton
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

@import "~bootstrap/scss/bootstrap";



@import "./app.scss";
@import "../pages/SearchResult/searchResults.scss";
@import "../components/style.scss";